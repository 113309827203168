.welcome {
  background-color: #1f1f1f;
  background-image: url('../../assets/images/bg.png');
  background-attachment: fixed;
  background-size: cover;
  line-height: 1.8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}




.welcome--logo {
  width: 10rem;
}

.welcome--about {
  padding: 2rem;
  width: 50rem;
  color: #444;
  text-align: justify;
  margin: auto;
}

.welcome__cta-primary {
  background: #e74c3c;
  color: #fff;
  margin-top: 1rem;
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 20px;
  font-weight: 700;
}
