.navbar {
  display: flex;
  background: rgb(0, 0, 0);
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2.5rem;
  position: absolute;
  z-index: 1;
  opacity: 50%;
  height: 25px;
  width: 100%;
}

h1 { color: White}
.navbar-mid {
  border: #222;
  background: #333;
  display: flex;
  justify-content: center;

  
}

.navbar-sticky {
  background: #333;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
}

.navbar--logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar--logo {
  width: 4rem;
  height: 4rem;
  margin-right: 0.0rem;
  animation: rotate 1.5s infinite;

}

.navbar--link {
  display: flex;
  color: white;
  list-style: none;

}

.navbar--link-item {
  color: white;
  margin: 0.4rem 1rem 0 0;
  padding: 0 0.3rem;
  cursor: pointer;
}

.navbar--link-item:hover {
  animation : rotate 1s infinite;
  background-color: yellow;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}